import React from 'react';

import './ChallengeView.css';

import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import { Card, Row } from 'react-bootstrap';

const ChallengeCard = ({ name = "", tag = '', category = "", title = "", challenge = (<></>), prize = (<></>) }) => (
	<div className='col-md-6 col-sm-12 mt-0 mb-5'>
		<div className="card p-3 mt-3 rounded">
			<div className="d-flex justify-content-between">
				<div className="d-flex flex-row align-items-center">
					<div className="icon"> <img src={require("../assets/images/" + name.toLocaleLowerCase() + ".png")} height={"30px"} /> </div>
				</div>

			</div>
			<div className="mt-2">
				<br></br>
				{/* <p className="heading">Releasing 2024-03-01 @ 9:30 PM</p> */}
				<h3 className="heading">{title}</h3>
				{tag != "" && <div className="badge"> <span>{tag}</span> </div>}
				<div className="mt-1">
					<div className="mt-3">
						<span className="text1">Challenge:
							<span className="text2"> {challenge}</span></span> </div>
					{prize != (<></>) && <div className="mt-3">
						<span className="text1">Prizes 🏆: <span style={{ fontWeight: 'normal' }}>{prize}</span></span> </div>}
				</div>

			</div>

		</div>
	</div>
);

const OveralPrizeCard = ({ name = "", tag = '', link = '', category = "", title = "", challenge = (<></>), prize = (<></>) }) => (
	<div className='col-md-6 col-sm-12 mt-0 mb-5'>
		<div className="card p-3 mt-3 rounded">
			<div className="d-flex justify-content-between">
				<div className="d-flex flex-row align-items-center">
					<div className="icon"> <img src={require("../assets/images/" + name.toLocaleLowerCase() + ".png")} height={"30px"} /> </div>
				</div>

			</div>
			<div className="mt-2">
				<br></br>
				{/* <p className="heading">Releasing 2024-03-01 @ 9:30 PM</p> */}
				<h3 className="heading">{title}</h3>
				{tag != "" && <div className="badge"> <span>{tag}</span> </div>}
				<div className="mt-1">
					{prize != (<></>) && <div className="mt-3">
						<span className="text1">Prizes 🏆: <span style={{ fontWeight: 'normal' }}>{prize}</span></span> </div>}
				</div>

			</div>

		</div>
	</div>
);

const ChallengesSection = () => (
	<div>
		<h1 className="text-gradient font-weight-bold mt-4">
			Challenges
		</h1>
		<Row className='mt-3 center'>
			<OveralPrizeCard
				name="uottahack"
				title="1st Place Overall"
				link=""
				tag="Powered by Best Buy"
				prize={(<span>4 x Meta Quest 2</span>)}
			/>
			<OveralPrizeCard
				name="uottahack"
				title="2nd Place Overall"
				tag="Powered by Best Buy"
				prize={(<span>4 x Apple Airpods</span>)}
			/>
			<OveralPrizeCard
				name="uottahack"
				title="3rd Place Overall"
				tag="Powered by Best Buy"
				prize={(<span>4 x Google Nest Mini & Fitbits</span>)}
			/>
			<ChallengeCard
				name="Solace"
				title="Solace PubSub+ Challenge"
				tag="International Challenge"
				challenge={(<span>Solace is hosting a challenge for this year's uOttaHack! to qualify for the grand prize, your hack must be comprised of more than one application that communicate through a Solace Event Broker. Your hack will be assessed on how innovative it is towards solving a real world problem, as well as how appropriately you've leveraged Event-Driven Architecture (EDA) to solve it. <a href='https://uottahack.solace.cloud/' target='_blank'>Click Me!</a></span>)}
				prize={(<span>4 x $250 Gift Cards</span>)}
			/>
			<ChallengeCard
				name="Ford"
				title="Enhancing the EV Experience"
				tag="International Challenge"
				challenge={(<div>Challenge

					Create a mobile or web Android app focused around improving the driver experience for electric vehicle owners. This app will provide the driver with real time data about their journey.


					<br></br>
					<br></br>
					<span style={{ fontWeight: 'bold' }}>Basic Requirements:</span>
					<br></br>
					The user must be able to:



					Enter parameters including, but not limited to:
					Departure location
					Destination

					Receive output that includes, but is not limited to:
					Duration of charge
					Charging points along the route


					Beyond that, we want you to put yourself in the driver’s seat and create an app with everything you want to see. Please use online resources for data points, but you can start here.


					<br></br>
					<br></br>
					<span style={{ fontWeight: 'bold' }}>Judging Criteria: </span>
					<br></br>
					Integration of requirements and features
					Overall user experience
					Creativity and design</div>)}
				prize={(<div>
					1st Place - 4 x $200 Gift Cards + Swag Bag
					<br></br>
					2nd Place - 4 x $100 Gift Cards
					<br></br>
					3rd Place - 4 x $50 Gift Cards
				</div>)}
			/>
			<ChallengeCard
				name="po"
				title="Office of The President Challenge Title"
				tag="Local Challenge"
				challenge={(<span>Inspired by the push to think about the social in online learning, we propose that you develop a tool or application that replicates a study hall (a bit like Discord, a bit like Reddit), where students can study together / chat (text or audio recordings) / share insights / ask questions of each other. With this tool or application, students would be doing online what they always used to do in the context of assignments and projects - collaborate with one another.</span>)}
				prize={(<span>4 x $500 Tuition Credits across University Institutions</span>)}
			/>
			<ChallengeCard
				name="CSE"
				title="CSE Machine Learning Challenge"
				tag="Local Challenge"
				challenge={(<span>Build a project that uses Machine Learning; Our criteria include application performance, focusing on speed, accuracy, model size, and resource allocation.</span>)}
				prize={(<span>4 x 50$ Gift Cards</span>)}
			/>
			<ChallengeCard
				name="Patagonia"
				title="Best Sustainability Hackathon"
				tag="Local Challenge"
				challenge={(<span>At Patagonia, we recognize the urgent need to protect all life on Earth from the threat of extinction. We are committed to leveraging our resources—our business, investments, voice, and imagination—to take action. The winner of this challenge will offer a unique sustainability solution that uses technology to actively contributes to protecting our planet.</span>)}
				prize={(<span>Patagonia Merch & Gear 👀👀</span>)}
			/>
			<ChallengeCard
				name="eh"
				title="Scoreboard Challenge"
				tag="Local Challenge"
				challenge={(<span>
					Welcome to the uOttaHack 6 scoreboard challenge. Throughout the weekend, you are able to collect points by:
					<div style={{ marginTop: '6px' }}>
						<ul>
							<li>1 Point - Join the DEVPOST.</li>
							<li>1 Point - Attending a workshop.</li>
							<li>4 Points - Submitting a project.</li>
							<li>8 Points - Find an OG <a href="https://2018.uottahack.ca/images/logo.png" target="_blank">2018 uOttaHack Sticker</a> in CRX (post proof in discord).</li>
						</ul>
					</div>
				</span>)}
				prize={(<span>3 x $525 (Top 3 Scores)</span>)}
			/>
			<ChallengeCard
				name="uottahack"
				tag="Local Challenge"
				title="Best Carleton x uOttawa Collab"
				challenge={(<span>: Calling all teams with Carleton University and University of Ottawa students! Compete to build the best collaborative project. Join forces with your friends from Carleton or uOttawa to shine in the Best Carleton x uOttawa Collab!</span>)}
				prize={(<span>4 x Stuffed Toy Pandas 🥹🥹</span>)}
			/>
		</Row>

		<Row className='mt-3 mb-5 center'>
			<ChallengeCard
				name="adobe"
				tag="Local Challenge"
				title="Most Creative Adobe Express Add-On"
				challenge={(<span>Adobe Express is an AI-first, all-in-one content creation app that makes it fast, easy and fun to design and share videos, images, PDFs, flyers, TikToks, logos and more. Best of all, it’s free to get started.

					Add-ons in Adobe Express are simply extensions and integrations that are built upon very powerful APIs. These APIs provide access to the user’s document, connections to third-party tools, and so much more. You can extend the functionality of Adobe Express using add-ons to unlock new capabilities and workflows. Check out these add-ons to see what other cool ideas developers have implemented!

					For this hackathon, we want you to build a creative add-on for Adobe Express. Users are looking for add-ons that involve assets like stickers or icons, work with presentations and data visualization, and work in roles like marketing and  e-commerce. Build an Adobe Express add-on this weekend for a chance to win a Wacom Wireless Drawing Tablet and JBL Go3 Speaker for you and each member of your team!</span>)}
				prize={(<span>4 x Wacom Wireless Drawing Tablet & JBL Go3 Speaker</span>)}
			/>
			<ChallengeCard
				name="auth"
				tag="Local Challenge"
				title="Best Use of Auth0"
				challenge={(<span>Auth0 wants your applications to be secure! Why spend hours building features like social sign-in, Multi-Factor Authentication, and passwordless log-in when you can enable them through Auth0 straight out of the box? Auth0 is free to try, doesn’t require a credit card, and allows for up to 7,000 free active users and unlimited log-ins. Make your new account today and use any of the Auth0 APIs for a chance to win a pair of wireless headphones and a battery pack for you and each member of your team!</span>)}
				prize={(<span>4 x Wireless Headphones & Battery Pack</span>)}
			/>
			<ChallengeCard
				name="tech"
				tag="Local Challenge"
				title="Best .Tech Domain Name"
				challenge={(<span>Make your Team's Achievements timeless: Win a .Tech Domain Name for Life to Showcase and Expand Your Project, Plus 4 Blue Snowball Mics for Effortless Collaboration on Zoom, empowering you to build even more cool things together!</span>)}
				prize={(<span>4 x Blue Snowball Microphone & a Free .Tech Domain Name for Life!</span>)}
			/>
			<ChallengeCard
				name="flow"
				tag="Local Challenge"
				title="Best Use of Flow"
				challenge={(<span>Flow is a public, decentralized, layer-one blockchain designed for creating limitless Web3 apps for mainstream adoption. Flow empowers hackers like you to build decentralized applications and share them with the world. Write safe and readable smart contracts with Cadence and explore the potential of composable, on-chain logic. With SDKs in multiple languages like Javascript, Go, Kotlin, Python, Swift, Unity, you can jump in and start your hack using Flow this weekend for a chance to win $200 USD worth of FLOW token for each member of your team!</span>)}
				prize={(<span>4 x $200 USD in FLOW Token</span>)}
			/>
			<ChallengeCard
				name="fidelity"
				tag="Local Challenge"
				title="Best DEI Hack sponsored by Fidelity"
				challenge={(<span>At Fidelity, Diversity, Equity, and Inclusion are viewed as major keys to the success of their business, which is why they are challenging you to come up with innovative ways to create forward-thinking financial tech that promotes equality for all people! We want you to use your hacking skills to find and build solutions that put financial opportunities and resources within reach for all communities, creating accessible, sustainable, and/or inclusive solutions. The team with the best hack will win a Fidelity branded wireless charger for each team member!</span>)}
				prize={(<span>4 x Fidelity Branded Wireless Charger</span>)}
			/>
			<ChallengeCard
				name="kintone"
				tag="Local Challenge"
				title="Best Use of Kintone"
				challenge={(<span>Still not sure what backend to use in your hack? Kintone is a low-code web database that lets you set up your backend in minutes. It's beginner friendly, allowing you to customize your Kintone app using nothing but JavaScript and HTML. If you're interested in IoT, your team can snag a FREE microcontroller by signing up for Kintone and visiting the MLH table. If you use Kintone in your hackathon project, you'll have a chance to win a breadboard IoT kit to power up the hardware hacking for each member of your team!</span>)}
				prize={(<span>4 x Breadboard IoT Kit</span>)}
			/>
			<ChallengeCard
				name="redbull"
				tag="Internation Challenge"
				title="Red Bull Can You Make It?"
				challenge={(<div>1. Gather Two Friends to Make a Three-Person Team.
					<br></br>
					<br></br>
					2. Create a Simple and Fun 60-Second Video Explaining Why Your Team Should Be Selected. *If You Want to Make Use of Music, Ensure You're Using the Music Provided on the Playlist Below.
					<br></br>
					<br></br>
					3. Selected Teams Will Be Notified No Later Than April 15, 2024.</div>)}
				prize={(<span>Trip through Europe! Teams from around the world have seven days to travel across Europe. Their only currency: Red Bull cans. In this journey full of adventure, charm and strategy, one question rises above all: Can You Make It? Throughout the journey, teams will exchange cans of Red Bull for everything they need – food, a place to sleep, transportation to the next location and anything else to get to the finish line in Berlin.</span>)}
			/>
			<ChallengeCard
				name="qq"
				title="Qualtia Challenge (Mexico)"
				tag="International Challenge"
				challenge={(<div>With the aim of increasing the SALES and the LOYALTY of customers and consumers, Qualtia look for innovative ideas that alone or combined with digital tools, provide different experiences and high perceived value for the customer, whether in the attraction, transaction or consumption of products.
					<br></br>
					<br></br>
					Deliverables:
					<br></br>
					-Client / directed channel.
					<br></br>
					-Stage of the process: Attraction, transaction or consumption
					<br></br>
					-Product line involved.
					<br></br>
					-Design or Prototype of the solution and, if applicable, of the digital tools involved.
					<br></br>
					-Detail how it would work.
					<br></br>
					-Description of the experience, impact on the client, what need it covers and why it would be easy to adopt.</div>)}
				prize={(<span>4 x Airpods and Alexas</span>)}
			/>


		</Row>

	</div>
);
const ChallengeView: React.FC = () => {
	return (
		<Container id="contact-view" fluid>
			<ChallengesSection />
		</Container>
	);
};

export default ChallengeView;
